<template>
  <div class="mb-2">
    <hr>
    <div class="item-top-area d-flex align-items-center">
      <div class="item-right mr-auto">
        <div class="switch-toggle">
          <span>⏱ Schedule <a v-if="!getSiteDetails.isWhiteLabel" target="_blank" style="color: #3988fe;"
                              href="https://docs.replug.io/article/698-what-is-bio-links">(help)</a></span>
        </div>
      </div>
      <div class="item-right ml-auto">
        <div class="switch-toggle">
          <label class="switch">
            <input @click="toggleSchedule()" :checked="schedule_enabled"
                   :value="schedule_enabled"
                   type="checkbox"
            />
            <div class="slider round"></div>
          </label>
        </div>
      </div>
    </div>
    <div v-if="schedule_enabled" class="mt-3">

      <div class="input_icon_text schedule_body pb-3 pt-3 d-flex justify-content-around align-items-center">
        <!-- start date and time  -->
        <div>
          <div class="d-flex align-items-center url_input_main ">
            <div class="content">
              <div class="url">Start Date</div>
              <div class="name">
                <div class="validation_input">
                  <b-form-datepicker :class="{'input-field-error': validations.start_date}" :min="min"
                                     v-model="schedule.start_date"
                                     @input="scheduleValidationOnKey($event, 'start_date')"
                                     class="data-picker"></b-form-datepicker>
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.start_date">{{ scheduleMessages.start_date }}</span>
          <div class="mt-3 d-flex align-items-center url_input_main ">
            <div class="content">
              <div class="url">End Date</div>
              <div class="name">
                <div class="validation_input">
                  <b-form-datepicker :class="{'input-field-error': validations.end_date}" :min="min"
                                     v-model="schedule.end_date"
                                     class="data-picker"></b-form-datepicker>
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.end_date">{{ scheduleMessages.end_date }}</span>
        </div>

        <!-- end date and time -->
        <div>
          <div class="d-flex align-items-center url_input_main ">
            <div class="content">
              <div class="url">Start Time</div>
              <div class="name">
                <b-form-timepicker :class="{'input-field-error': validations.start_time}" class="time-picker"
                                   v-model="schedule.start_time"></b-form-timepicker>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.start_time">{{ scheduleMessages.start_time }}</span>
          <div class="mt-3 d-flex align-items-center url_input_main ">
            <div class="content">
              <div class="url">End Time</div>
              <div class="name">
                <b-form-timepicker :class="{'input-field-error': validations.end_time}" class="time-picker"
                                   v-model="schedule.end_time"></b-form-timepicker>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.end_time">{{ scheduleMessages.end_time }}</span>
        </div>
      </div>

      <!-- Time zone -->
      <b-dropdown ref="campaigns_dropdown_quick_link" right class="mt-3 dropdown-menu-right default_style_dropdown"
                  :no-caret="true">
        <div :class="{'input-field-error': validations.time_zone}"
             class="dropdown_header br-10 rounded_shape d-flex align-items-center" slot="button-content"
             data-cy="select-camp">
          <div class="name">
            <p v-if="schedule.time_zone">{{ schedule.time_zone }}</p>
            <p v-else>Select Time zone</p>
          </div>
          <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
        </div>
        <ul id="campaign-dropdown-scroll" class="clear inner">
          <template>
            <div class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation d-flex">
              <input placeholder="Search for timezone"
                     @input="searchTimeZone($event)"
                     type="text" class="search_input_block_for_dropdown" v-model="search_query">
              <i class="fal fa-search"></i>
              <hr>
            </div>
            <li class="list_item_li" @click="$refs.campaigns_dropdown_quick_link.visible = false"
                v-for="item in timeZoneList"
                @click.prevent="updateTz(item)"
                :key="item">
              <div class="d-flex align-items-center profile__box">

                <div class="content">
                  <div class="name">
                    {{ item }}
                  </div>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </b-dropdown>
      <span class="input-error" v-if="validations.time_zone">{{ scheduleMessages.time_zone }}</span>
    </div>
  </div>
</template>
<script>
import {scheduleMessages, scheduleValidations} from '@/common/attributes'
import timezones from '@/config/timezones'
import {defaultScheduleObj} from '@/common/defaults'

export default ({
  data() {
    return {
      search_query: '',
      min: new Date(),
      timeZoneList: timezones,
      scheduleMessages: scheduleMessages,
      schedule_enabled: this.enabled,
      schedule: {...defaultScheduleObj, ...this.data},
      validations: {...scheduleValidations}
    }
  },
  props: [
    'enabled',
    'data'
  ],
  methods: {
    toggleSchedule() {
      this.schedule_enabled = !this.schedule_enabled
    },
    scheduleValidationOnKey(key, value) {
      this.validations[key] = this.requiredCheck(value)
    },
    updateTz(tz) {
      this.scheduleValidationOnKey('time_zone', tz)
      this.$refs.campaigns_dropdown_quick_link.hide(true)
      this.timeZoneList = timezones
      this.search_query = ''
      this.schedule.time_zone = tz
    },
    searchTimeZone(evt) {
      const value = evt.target.value
      if (value === '') {
        this.timeZoneList = timezones
        return
      }

      this.timeZoneList = timezones.filter(el => el.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    },
    /**
     * Checking Schedule validations
     * @returns {this is string[]}
     */
    checkScheduleValidation() {
      this.validations.start_date = this.requiredCheck(this.schedule.start_date)
      this.validations.end_date = this.requiredCheck(this.schedule.end_date)
      this.validations.start_time = this.requiredCheck(this.schedule.start_time)
      this.validations.end_time = this.requiredCheck(this.schedule.end_time)
      this.validations.time_zone = this.requiredCheck(this.schedule.time_zone)
      return Object.keys(this.validations).every(k => this.validations[k] === false)
    },
    /**
     * Final checking on schedule before submission
     * @returns {Promise<boolean>}
     */
    async checkScheduleSubmission() {
      if (!this.schedule_enabled) {
        return true
      }

      const check = this.checkScheduleValidation()
      if (!check) {
        await this.$store.dispatch('toastNotification', {message: 'Schedule validation failed.', type: 'error'})
        return false
      }

      return true
    }
  },
  watch: {
    'schedule.start_date'(value) {
      this.scheduleValidationOnKey('start_date', value)
    },
    'schedule.end_date'(value) {
      this.scheduleValidationOnKey('end_date', value)
    },
    'schedule.start_time'(value) {
      this.scheduleValidationOnKey('start_time', value)
    },
    'schedule.end_time'(value) {
      this.scheduleValidationOnKey('end_time', value)
    },
    'schedule.time_zone'(value) {
      this.scheduleValidationOnKey('time_zone', value)
    }
  }
})
</script>

<style scoped lang="less">
.schedule_body {
  div {
    .url_input_main {
      .content {
        .validation_input, .name {
          .form-control, .data-picker {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
</style>
